import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '../api';
import { Observable, map } from 'rxjs';
import { CollectionsDto, CollectionsStatsDto, GeoObjectGroup, LedgerStatusDto, LedgerTransportFormat, TransportFormatDto, GetCollectionRecordsDto } from '@involi/api-shared';
import { ApiClient } from '../core/api-client';
import { RecordTT } from '@involi/format';
import { ApiStatusService } from '../core';

@Injectable({
    providedIn: 'root'
})
export class LedgerApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                http: HttpClient,
                apiStatus: ApiStatusService)
    {
        super(apiStatus, http, 'Ledger');
        this.apiUrl = `${config.involiApiUrl}/ledger`;
    }

    getCollections(): Observable<CollectionsDto>
    {
        return this.get<CollectionsDto>(`${this.apiUrl}/collections`, { collections: [] });
    }

    getCollectionsStats(): Observable<CollectionsStatsDto>
    {
        return this.get<CollectionsStatsDto>(`${this.apiUrl}/collections/stats`, { collections: [] });
    }

    getCollectionRecords(collectionName: string, since?: number): Observable<GeoObjectGroup[]>
    {
        const request: GetCollectionRecordsDto = { since };
        return this.post<GeoObjectGroup[]>(`${this.apiUrl}/collection/${collectionName}/records`, request);
    }

    getCollectionPrimaryIds(collectionName: string): Observable<string[]>
    {
        return this.get<string[]>(`${this.apiUrl}/collection/${collectionName}/primary-ids`);
    }

    purgeCollection(collectionName: string): Observable<LedgerStatusDto>
    {
        return this.post<LedgerStatusDto>(`${this.apiUrl}/collection/${collectionName}/purge`, {});
    }

    pauseProcessing(): Observable<void>
    {
        return this.post(`${this.apiUrl}/processing/pause`, {});
    }

    resumeProcessing(): Observable<void>
    {
        return this.post(`${this.apiUrl}/processing/resume`, {});
    }

    getRecord(primaryId: string): Observable<RecordTT>
    {
        return this.get<RecordTT>(`${this.apiUrl}/record/${primaryId}`);
    }

    getTransportFormat(): Observable<LedgerTransportFormat>
    {
        return this.get<TransportFormatDto>(`${this.apiUrl}/config/transport`).pipe(
            map((dto: TransportFormatDto) => dto.format)
        );
    }

    setTransportFormat(format: LedgerTransportFormat): Observable<void>
    {
        const request: TransportFormatDto = { format };
        return this.post<void>(`${this.apiUrl}/config/transport`, request);
    }
}